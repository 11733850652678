<template>
	<div>
    <div class="table-list">
      <Table :loading="loading" :columns="columns" :data="data"></Table>
    </div>
    <div class="ser-page">
      <Page class-name="page-r" @on-change="changePage" @on-page-size-change="changePageSize" :total="search.total" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-sizer show-total></Page>
    </div>
  </div>
</template>

<script>
export default{
  data(){
    return{
      loading:false,
      search:{
        page:1,
        pageSize:15,
        total:0
      },
      columns:[
        {title:'序号',type:'index'},
        {title:'操作人',key:'name'},
        {title:'操作方法',key:'act_url'},
        {title:'操作信息',key:'info'},
        {title:'操作时间',key:'act_time',sortable:true},
        {title:'ip',key:'act_ip'},
      ],
      data:[]
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      var that = this
      this.loading = true
      this.requestApi('/adm/get_super_log',this.search).then(function (res) {
        if (res.status){
          that.search.total = res.data.total
          that.data = res.data.data
        }
        that.$nextTick(function () {
          that.loading = false
        })
      })
    },
    changePage(page){
      this.search.page = page
      this.init()
    },
    changePageSize(size){
      this.search.page = 1
      this.search.pageSize = size
      this.init()
    },
  }
}
</script>

<style>
</style>
